import React from "react"
import { createUrlQueryParams } from "../../common/gFunctions";

import { getSubscriptionPrefs, updateSubscriptionPrefs } from "../../services/customerInfo";

import ProceedButton from "../proceedButton";

import styles from "./index.module.css";

import DefaultLoader from "../../shared/loader";
import { Helmet } from "react-helmet";
import { RecaptchaPublicKey } from "../../common/constants";

export class CleverTapUnsubscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionAuth: null,
            subscriptionPrefs: null,
            isSuccess: false,
            isError: false,
            isLoading: false
        }
    }
    componentDidMount() {
        // console.log('SubscriptionPrefsLocation', this.props.location);
        var subscriptionPrefs = null;
        var originalSubscriptionPrefs = null;
        var subscriptionAuth = null;
        // console.log(window.clevertap, '$WZRK_WR')

        try {
            this.loadCleverTapScript();
            this.loadCleverTapUnsubscribeScript();
            this.getCleverTapUnsubscribeEmail();
            // var subscriptionAuthSplit = this.props.location.pathname.split('/');
            // subscriptionAuth = {
            //     encodedEmailAddr: decodeURIComponent(subscriptionAuthSplit[2]),
            //     encodedEmailJobId: subscriptionAuthSplit[3],
            //     expirationDt: subscriptionAuthSplit[4],
            //     signature: subscriptionAuthSplit[5]
            // }
        } catch {
        }
    }

    loadCleverTapScript() {
        var clevertap = {event:[], profile:[], account:[], onUserLogin:[], notifications:[], privacy:[], region:'in1', spa: true};

        // var cleverTapAccountID = environment.cleverTapProjectID;
        var cleverTapAccountID = process.env.GATSBY_CLEVERTAP_PROJECT_ID;
        if (!cleverTapAccountID || cleverTapAccountID == '') return;
        
        // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
        clevertap.account.push({"id": cleverTapAccountID});
        clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
        clevertap.privacy.push({useIP: false}); //set the flag to true, if the user agrees to share their IP data
        
        window.clevertap = clevertap;
        var wzrk = document.createElement('script');
        wzrk.type = 'text/javascript';
        wzrk.async = true;
        wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/clevertap.min.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(wzrk, s);
    }

    loadCleverTapUnsubscribeScript = () => {
        localStorage.removeItem('wzrk_email');
        const scriptContent = `
        function wzrk_email_fetched(emailStr) {
            if (localStorage) {
                localStorage.setItem('wzrk_email',emailStr);
            }
        }
        `;

        // Create a script element
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.text = scriptContent;

        // Append the script to the document's head
        document.head.appendChild(scriptElement);

        var count = 0;
        var checkIfEmailFetchedInterval = setInterval(() => {
            count++;
            var email = localStorage.getItem('wzrk_email');
            console.log('wzrk_email',email);

            if (email) {
                this.setState({
                    subscriptionAuth: {
                        encodedEmailAddr: encodeURIComponent(email),
                        isFromCT: true
                    }
                }, () => {
                    this.fetchSubscriptionPrefs();
                });
            }

            if (email || count == 5) {
                clearInterval(checkIfEmailFetchedInterval);
            }
        }, 2000);
    }

    getCleverTapUnsubscribeEmail() {
        var count = 0;
        var interval = setInterval(() => {
            if (window.$WZRK_WR) {
                clearInterval(interval);
                window.$WZRK_WR.getEmail(false);
            }
            count++;
            if (count === 5) {
                clearInterval(interval);
            }
        }, 1000);
    }

    saveSubscriptionPrefs() {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RecaptchaPublicKey, {action: 'ctunsubscribe'}).then((recaptchaToken) => {
                this.setState({isLoading: true});
                updateSubscriptionPrefs({...this.state.subscriptionAuth, recaptchaToken}, this.state.subscriptionPrefs)
                .then(({error,response}) => {
                    console.log(error,response,'getSubscriptionPrefs')
                    if (error.status < 400) {
                        this.setState({isError: false, isSuccess: true, isLoading: false, isUnsubscribed: !this.state.subscriptionPrefs.IsSubscribedMarketingEmails})
                    } else {
                        this.setState({isLoading: false, isInvalid: error.status == 400, isUnsubscribed: false})
                    }
                })
            });
        });
    }

    fetchSubscriptionPrefs() {
        var subscriptionAuth = this.state.subscriptionAuth;
        getSubscriptionPrefs(subscriptionAuth)
            .then(({error,response}) => {
                console.log(error,response,'getSubscriptionPrefs')
                if (error.status < 400) {
                    var subscriptionPrefs = {...response};
                    var originalSubscriptionPrefs = {...response};
                    this.setState({subscriptionAuth, subscriptionPrefs, originalSubscriptionPrefs});
                } else {
                    this.setState({isInvalid: true});
                }
            })
    }

    onChangeSubscriptionPrefs = (e) => {
        const value = e.target.checked;
        const section = this.state.subscriptionPrefs;
        section[e.target.name] = value;
        this.setState({[section]: section});
    }

    render() {
        const {subscriptionAuth, subscriptionPrefs, originalSubscriptionPrefs, isLoading, isSuccess, isInvalid, isUnsubscribed} = this.state;
        console.log(subscriptionPrefs, originalSubscriptionPrefs, 'subscriptionPrefs');
        return (
            <>
                <Helmet>
                    <script id="grecaptcha" src="https://www.google.com/recaptcha/api.js?render=6Lf-654UAAAAAFBeUNWxDq4xYA2DQO-oUhuXCqpY"></script>
                </Helmet>
                {
                    (isSuccess || isInvalid) ?
                    (
                        <div>
                            <span className={styles.statusMsg}>{isSuccess ? (isUnsubscribed ? 'You have been unsubscribed successfully.' : 'Your subscription preferences have been saved successfully.') : 'Please note that this unsubscribe link has expired'}</span>
                        </div>
                    )
                    :
                    subscriptionAuth && subscriptionPrefs ?
                    <>
                    <div className={[styles.emailContainer].join(' ')}>
                        <span className={[styles.emailLabel].join(' ')}>Email</span>
                        <span className={[styles.emailValue].join(' ')}>{decodeURIComponent(subscriptionAuth.encodedEmailAddr)}</span>
                    </div>
                    <div className={["custom-checkbox", styles.subscriptionPrefsCheckboxContainer].join(" ")}>    
                        <input className={["custom-input",styles.subscriptionPrefsCheckbox].join(" ")} id="subscription-prefs-marketing" name="IsSubscribedMarketingEmails" type="checkbox" checked={subscriptionPrefs.IsSubscribedMarketingEmails} onChange={(event)=>this.onChangeSubscriptionPrefs(event)} />
                        <label className={styles.subscriptionPrefsCheckboxText} htmlFor="subscription-prefs-marketing">Subscribed to Newsletter and Marketing Emails</label>
                    </div>
                    <div className={[styles.proceedButtonContainer].join(' ')}>
                        <ProceedButton buttonText={'Save'} onClick={() => {this.saveSubscriptionPrefs()}} borderRadius={3} disabled={!originalSubscriptionPrefs || !subscriptionPrefs || (originalSubscriptionPrefs.IsSubscribedMarketingEmails == subscriptionPrefs.IsSubscribedMarketingEmails)} loading={isLoading}/>
                    </div>
                    </>
                    :
                    <>
                    <DefaultLoader isLoad={true} isFull={true}/>
                    </>
                }
            </>
        )
    }
}

export default CleverTapUnsubscribe
